const ADD_CART_PRODUCT = "ADD_CART_PRODUCT";
const REMOVE_CART_PRODUCT = "REMOVE_CART_PRODUCT";
const ADD_PROMO_CODE = "ADD_PROMO_CODE";
const REMOVE_PROMO_CODE = "REMOVE_PROMO_CODE";
const REMOVE_SWAL_TEXT = "REMOVE_SWAL_TEXT";
const UPDATE_CART_PRODUCT = "UPDATE_CART_PRODUCT";
const CHANGE_CART_PRODUCT_QUANTITY = "CHANGE_CART_PRODUCT_QUANTITY";
const CLEAR_CART = "CLEAR_CART";
const EMPTY_CART_FULL = "EMPTY_CART_FULL";
const ADD_TRAN_REF = "ADD_TRAN_REF";
const TOGGLE_PROMOTION_ADD = "TOGGLE_PROMOTION_ADD";

export const types = {
    ADD_CART_PRODUCT,
    REMOVE_CART_PRODUCT,
    ADD_PROMO_CODE,
    REMOVE_PROMO_CODE,
    REMOVE_SWAL_TEXT,
    UPDATE_CART_PRODUCT,
    CHANGE_CART_PRODUCT_QUANTITY,
    CLEAR_CART,
    EMPTY_CART_FULL,
    ADD_TRAN_REF,
    TOGGLE_PROMOTION_ADD
};
