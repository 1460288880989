const SHOW_SPINNER = "SHOW_SPINNER";
const HIDE_SPINNER = "HIDE_SPINNER";
const SET_ACTIVE_LANGUAGE = "SET_ACTIVE_LANGUAGE";
const SET_STORE_IP_DETAILS = "SET_STORE_IP_DETAILS";
export const types = {
  SHOW_SPINNER,
  HIDE_SPINNER,
  SET_ACTIVE_LANGUAGE,
  SET_STORE_IP_DETAILS,
};
