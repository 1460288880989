import { types } from "./types.js";

let initialState = {
  cart: [],
  paymentDetails: {
    productsValue: 0,
    discount: 0,
    shippingCharges: 0,
    subTotal: 0,
    finalTotal: 0,
    shippingCharges: 0,
  },
  promoCode: {},
  cartBit: false,
  swalMsg: "",
  tran_ref: "",
  order_id: "",
  promoAdvertisement:false,
};
const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_CART_PRODUCT: {
      let newstate = state;
      let existingProductBit = false;
      let existingProduct = newstate.cart.filter((x) => {
        // let oldPrice = x.quantity * x.price
        // let newPrice = action.payload.data.quantity * x.price
        if (x.productCode == action.payload.data.productCode) {
          existingProductBit = true;
          x.quantity += 1;
          newstate.cartBit = !newstate.cartBit;
        }

        return x;
      });

      if (action.payload.data.invoker == "cart") {
        newstate.swalMsg = "Product added to cart successfully";
      }

      if (!existingProductBit) {
        newstate.cart.push(action.payload.data);
        newstate.cartBit = !newstate.cartBit;
        if (action.payload.data.invoker == "cart") {
          newstate.swalMsg = "Product Added to cart successfully";
        }
      }

      return {
        ...newstate,
      };
    }

    case types.ADD_PROMO_CODE: {
      let initState = JSON.parse(JSON.stringify(state));
      initState.promoCode = action.payload.promoCode;
      return initState;
    }
    case types.REMOVE_PROMO_CODE: {
      let initState = JSON.parse(JSON.stringify(state));
      initState.promoCode = {};
      return initState;
    }

    case types.REMOVE_CART_PRODUCT: {
      let newstate2 = state;
      let product = newstate2.cart.find(
        (x) => x.productCode == action.productCode
      );
      let price = 0;
      if (product.discounted_price > 0) {
        price = product.quantity * product.discounted_price;
      } else {
        price = product.quantity * product.price;
      }

     

      newstate2.cart = newstate2.cart.filter(
        (x) => x.productCode != action.productCode
      );

      newstate2.cartBit = !newstate2.cartBit;

      if (action.invoker == "wishlist") {
        newstate2.swalMsg = "Product added to wishlist";
      } else {
        newstate2.swalMsg = "Product removed from cart";
      }
      return {
        ...newstate2,
      };
    }
    case types.EMPTY_CART_FULL: {
      let obj = {
        cart: [],
        paymentDetails: {
          productsValue: 0,
          discount: 0,
          shippingCharges: 0,
          subTotal: 0,
          finalTotal: 0,
          shippingCharges: 0,
        },
        promoCode: {},
        cartBit: false,
        swalMsg: "",
        tran_ref: "",
        order_id: "",
      };

      obj.cartBit = !state.cartBit;
      if (action.invoker == "paymentSuccess") {
        obj.swalMsg = "Order placed successfully";
      } else {
        obj.swalMsg = "";
      }
      return obj;
    }

    case types.REMOVE_SWAL_TEXT: {
      let newstate2 = state;
      newstate2.swalMsg = "";
      return {
        ...newstate2,
      };
    }
    case types.ADD_TRAN_REF: {
      let newstate2 = state;

      newstate2.tran_ref = action.tran_ref;
      newstate2.order_id = action.order_id;
      newstate2.session_id = action.session_id;
      return {
        ...newstate2,
      };
    }
    case types.REMOVE_TRAN_REF: {
      let newstate2 = state;
      newstate2.tran_ref = "";
      newstate2.order_id = "";
      return {
        ...newstate2,
      };
    }

    case types.TOGGLE_PROMOTION_ADD: {
      let newstate = state;
      newstate.promoAdvertisement = action.payload;

      return {
        ...newstate,
      };
    }

    case types.CHANGE_CART_PRODUCT_QUANTITY: {
      let newstate = state;
      let product = {};
      // console.log("action.payload", action.payload)
      newstate.cart.forEach((element2, index2, array) => {
        if (element2.productCode == action.payload.productCode) {
          product = element2;
          array[index2].quantity = action.payload.quantity;
        }
      });



      newstate.cartBit = !newstate.cartBit;

      return {
        ...newstate,
      };
    }

    default: {
      return state;
    }
  }
};
export default cartReducer;
